<template>
	<div class="addmerchants">
		<header>Matches > Add Match</header>
		<el-select v-model="stage" placeholder="Stage">
			<el-option
				v-for="item in options"
				:key="item.value"
				:label="item.label"
				:value="item.value">
				</el-option>
		  </el-select>
		<el-date-picker
		  v-model="time"
		  class="mt10"
		  type="datetime"
		  placeholder="Select the match date and time">
		</el-date-picker>
<!--		<el-input class="mt10" type="number" min="0" v-model="score" placeholder="Please enter match score"></el-input>-->
		<div class="matchebox">
			<div class="inputbox">
				<el-input class="nameinput" v-model="onename" placeholder="Team 1 Name">
				</el-input>
				<div class="uploadhead">
					<div class="uploadtitle">Team 1 Logo</div>
					<div class="uploadlabel">jpg/png files with a size less than 500kb</div>
				</div>
				<el-upload class="avatar-uploader" :data="params" accept="image/png, image/jpeg" :action="url+'/apis/uploads'"
					:show-file-list="false" :on-success="handleAvatarSuccessOne" :before-upload="beforeAvatarUpload">
					<img v-if="oneavatar" :src="oneavatar | formatImage" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					<span v-if="!oneavatar" class="uploadspan">Add Image</span>
				</el-upload>
			</div>
			<div class="inputbox">
				<el-input class="nameinput" v-model="twoname" placeholder="Team 2 Name">
				</el-input>
				<div class="uploadhead">
					<div class="uploadtitle">Team 2 Logo</div>
					<div class="uploadlabel">jpg/png files with a size less than 500kb</div>
				</div>
				<el-upload class="avatar-uploader" :data="params" accept="image/png, image/jpeg" :action="url+'/apis/uploads'"
					:show-file-list="false" :on-success="handleAvatarSuccessTwo" :before-upload="beforeAvatarUpload">
					<img v-if="twoavatar" :src="twoavatar | formatImage" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					<span v-if="!twoavatar" class="uploadspan">Add Image</span>
				</el-upload>
			</div>
		</div>
		<div class="btns">
			<el-button size="small" class="Cancelbtn" @click="cancel">Cancel</el-button>
			<el-button size="small" class="Confirmbtn" @click="submit">Confirm</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				time: '',
				score: '',
				onename: '',
				oneavatar: '',
				twoname: '',
				twoavatar: '',
				url: '',
				params:{},
				id:'',
				options: [{
					value: 'Final',
					label: 'Final'
				}, {
					value: 'Group Stage',
					label: 'Group Stage'
				}],
				stage: ''
			}
		},
		created() {
			this.url = this.$root.URL
			this.params.token = this.$store.state.token
			if(this.$route.query && this.$route.query.id){
				this.id = this.$route.query.id
				this.getdata()
			}
		},
		methods: {
			getdata(){
				var query = this.$qs.stringify({
					token: this.$store.state.token,
					id: this.id
				})
				this.$axios({
					url:'/matche?'+ query
				}).then(res=>{
					var data
					if(res.data){
						if(res.data.code==200){
							data = res.data.data
							this.stage = data.stage
							this.time = new Date(data.time * 1000)
							// this.score = data.score
							this.onename = data.onename
							this.oneavatar = data.oneavatar
							this.twoname = data.twoname
							this.twoavatar = data.twoavatar
						}else{
							this.$message({
								type:'error',
								message:res.data.msg
							})
						}
					}else{
						this.$message({
							type:'error',
							message:'service error'
						})
					}
				})
			},
			handleAvatarSuccessOne(response, file, fileList) {
				if(response.code==200){
					this.oneavatar = response.data
				}else{
					this.$message({
						type:'error',
						message:response.msg
					})
				}
			},
			handleAvatarSuccessTwo(response, file, fileList) {
				if(response.code==200){
					this.twoavatar = response.data
				}else{
					this.$message({
						type:'error',
						message:response.msg
					})
				}
			},
			beforeAvatarUpload(file) {
				return new Promise((resolve, reject) => {
					const isJPEG = file.type === 'image/jpeg';
					const isPNG = file.type === 'image/png';
					const isPG = (isJPEG || isPNG) //限制图片格式为jpg / png
					const isLt5M = file.size / 1024 / 1024 < 0.5; //限制图片大小
					if (!isPG) {
						this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
						return reject(false)
					} else if (!isLt5M) {
						this.$message.error('上传图片大小不能超过 500kb!');
						return reject(false)
					} else {
						return resolve(true);
					}
				});
			},
			cancel(){
				this.$router.back()
			},
			submit(){
				let time = Math.round(new Date(this.time) / 1000)
				if(!time){
					return this.$message({
						type:'error',
						message:'matche time no empty'
					})
				}
				if(!this.stage){
					return this.$message({
						type:'error',
						message:'stage no empty'
					})
				}
				// if(!this.score){
				// 	return this.$message({
				// 		type:'error',
				// 		message:'score no empty'
				// 	})
				// }
				if(!this.onename){
					return this.$message({
						type:'error',
						message:'team 1 name no empty'
					})
				}
				if(!this.oneavatar){
					return this.$message({
						type:'error',
						message:'team 1 icon no empty'
					})
				}
				if(!this.twoname){
					return this.$message({
						type:'error',
						message:'team 2 name no empty'
					})
				}
				if(!this.twoavatar){
					return this.$message({
						type:'error',
						message:'team 2 icon no empty'
					})
				}
				var data = {
						token:this.$store.state.token,
						stage: this.stage,
						time:time,
						score:Number(this.score),
						onename:this.onename,
						oneavatar:this.oneavatar,
						twoname:this.twoname,
						twoavatar:this.twoavatar
					}
				if(this.id){
					data.id = this.id
				}
				this.$axios({
					url:'/matche',
					method:'post',
					data:data
				}).then(res=>{
					console.log(res)
					if(res.data){
						if(res.data.code==200){
							this.$message({
								type:'success',
								message:'success'
							})
							this.$router.push({
								path:'/matches'
							})
						}else{
							this.$message({
								type:'error',
								message:res.data.msg
							})
						}
					}else{
						this.$message({
							type:'error',
							message:'service error'
						})
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.addmerchants {
		width: 500px;
		padding: 20px;

		header {
			color: #fff;
			font-size: 20px;
			padding: 8px 0 18px 0;
		}
		img.avatar{
			z-index: 99999999;
		}
		.el-input,
		.el-input-number {
			width: 100%;
		}
		.matchebox{
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
		}
		.uploadhead{
			padding: 10px 0;
		}

		.inputbox {
			height: 260px;
			width: 240px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}

		.btns {
			padding: 60px 0;
		}
	}
</style>
